import React, { FC } from 'react'
import { map } from 'lodash/fp'
import MenuPage, { MenuElementType, toConfig } from 'components/MenuPage'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import useFeatureFlags from 'hooks/useFeatureFlags'

const getIntl: (key1: string, key2: string) => string = (key1, key2) => {
  return `Quality.Menu.CallToActions.${key1}.${key2}`
}

const QualityAndTrainingMenu: FC = () => {
  const featureFlags = useFeatureFlags()
  const deviceOversight = featureFlags?.qualityReports
  const operatorTraining = featureFlags?.operatorTraining

  const config: Array<MenuElementType> = []

  if (deviceOversight) {
    config.push({
      key: 'Device',
      path: Path.Quality_QuickScanCalibration,
    })
  }

  if (operatorTraining) {
    config.push({
      key: 'Training',
      path: Path.Quality_AssignedTrainings,
    })
  }

  config.push({
    key: 'QuickCheck',
    path: Path.Quality_QuickCheckForm,
  })

  return (
    <MenuPage
      title={intlMessageForId('Quality.Header')}
      testId="QualityAndTrainingMenu"
      config={map(toConfig(getIntl))(config)}
    />
  )
}

export default QualityAndTrainingMenu

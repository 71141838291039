import React, { FunctionComponent, ReactChild, ReactElement } from 'react'
import styled from '@emotion/styled'
import { intlMessageForId } from 'localization'
import Color from 'types/color'
import useBanner from 'hooks/useBanner'
import NavBar, { NavBarTotalWidth } from 'components/NavBar'
import NavLogo from 'assets/svg/nav-logo.svg'
import Banner from './Banner'

export const PRINT_ROW_GAP = '12px'

const offset = `${NavBarTotalWidth.value}${NavBarTotalWidth.units}`

const Content = styled.div<{ marginLeft: string | number }>`
  margin-left: ${({ marginLeft }) => marginLeft};
  background-color: ${Color.White};
  flex-grow: 1;
  padding: 48px;

  @media print {
    margin-left: 0;
    padding: 8px 16px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 48px;

  @media print {
    padding-bottom: ${PRINT_ROW_GAP};
  }
`

export const Header = styled.header`
  color: ${Color.Black};
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;

  @media print {
    font-size: 24px;
  }
`

export const NoPrint = styled.div`
  @media print {
    display: none;
  }
`

export const OnlyPrint = styled.div`
  @media screen {
    display: none;
  }
`

const getMetaTitle: (metaTitle?: string) => string = (metaTitle) =>
  metaTitle || intlMessageForId('AppName')

const Page: FunctionComponent<{
  testId: string
  title?: string
  metaTitle?: string
  headerElement?: ReactElement
  children: ReactChild | ReactChild[]
  showNav?: boolean
  style?: React.CSSProperties
}> = ({
  title,
  metaTitle = title,
  testId,
  headerElement,
  children,
  showNav = true,
  style = {},
}) => {
  const bannerProps = useBanner()
  return (
    <div data-testid={testId} style={style}>
      <title>{getMetaTitle(metaTitle)}</title>
      <NoPrint>{showNav && <NavBar />}</NoPrint>
      {bannerProps && (
        <Banner
          status={bannerProps.status}
          message={bannerProps.message}
          marginLeft={showNav ? offset : 0}
        />
      )}
      <Content marginLeft={showNav ? offset : 0}>
        <OnlyPrint>
          <img
            src={NavLogo}
            alt="EnviroLogix"
            width="200"
            style={{ marginBottom: PRINT_ROW_GAP }}
          />
        </OnlyPrint>
        {title && (
          <HeaderContainer>
            <div style={{ flexGrow: 1 }}>
              <Header>{title}</Header>
            </div>
            {headerElement}
          </HeaderContainer>
        )}
        {children}
      </Content>
    </div>
  )
}

export default Page

import React, { FC, useState } from 'react'
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import { Formik, Form } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import { PrimaryButton, SecondaryButton } from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import {
  FormInput,
  FormSelect,
  REQUIRED_ERROR_MESSAGE,
  REQUIRED_NUMERIC_ERROR_MESSAGE,
} from 'components/FormElements'
import { handleCompleted, handleError } from 'components/FormElements/common'
import Page from 'components/Page'
import { Loading, Error } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import { intlMessageForId } from 'localization'
import { Path } from 'types/enums'
import { FormName } from 'types/forms'
import { logError } from 'utils'
import { validTestKits } from './constants'

interface FormValues {
  [FormName.SampleId]: string
  [FormName.ResultNumeric]: string
  [FormName.Analyte]: string
  [FormName.Operator]: string
  [FormName.Location]: string
  [FormName.TestKit]: string
  [FormName.SampleType]: string
}

const initialValues: FormValues = {
  [FormName.SampleId]: '',
  [FormName.ResultNumeric]: '',
  [FormName.Analyte]: '',
  [FormName.Operator]: '',
  [FormName.Location]: '',
  [FormName.TestKit]: '',
  [FormName.SampleType]: '',
}

export const QUICKCHECK_SAMPLES_QUERY = gql`
  query {
    getQuickcheckSamples {
      sampleId
    }
  }
`

export const CREATE_MANUAL_QUICKCHECK_MUTATION = gql`
  mutation(
    $analyte: String!
    $location: String!
    $numericResult: String!
    $operator: String!
    $sampleId: String!
    $sampleType: String!
    $testKit: String!
  ) {
    createManualQuickcheck(
      input: {
        analyte: $analyte
        location: $location
        numericResult: $numericResult
        operator: $operator
        sampleId: $sampleId
        sampleType: $sampleType
        testKit: $testKit
      }
    ) {
      successful
      messages {
        field
        message
      }
    }
  }
`

const HelpConatiner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const buildOptions = (values: string[]) => {
  return ['', ...values].map((element: string, index: number) => ({
    disabled: index === 0,
    label: element,
    value: element,
  }))
}

const QuickCheckForm: FC = () => {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(QUICKCHECK_SAMPLES_QUERY)
  const [resetForm, setResetForm] = useState<() => void>()

  const [createManualQuickcheck] = useMutation(
    CREATE_MANUAL_QUICKCHECK_MUTATION,
    {
      onCompleted: ({ createManualQuickcheck: { successful } }) => {
        if (successful) {
          handleCompleted(
            navigate,
            Path.Quality_QuickCheckForm,
            'QuickCheck successfully submitted'
          )

          window.scrollTo(0, 0)

          if (resetForm) {
            resetForm()
          }
        } else {
          handleError(navigate, Path.Quality_QuickCheckForm)
        }
      },
      onError: (e: ApolloError) => {
        handleError(navigate, Path.Quality_QuickCheckForm)
        logError(e.message, 'not-production')
      },
    }
  )

  if (loading) return <Loading />
  if (error) return <Error />

  const lowerCaseValidQuickcheckSampleIds = data.getQuickcheckSamples.map(
    (quickcheckSample: { sampleId: string }) =>
      quickcheckSample.sampleId.toLowerCase()
  )

  const lowerCaseValidTestKits = validTestKits.map((testKit) =>
    testKit.toLowerCase()
  )

  const validationSchema = Yup.object().shape({
    [FormName.SampleId]: Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .test(FormName.SampleId, 'Not a valid sample ID code', (value) =>
        lowerCaseValidQuickcheckSampleIds.includes(value?.toLowerCase())
      ),
    [FormName.ResultNumeric]: Yup.number()
      .typeError(REQUIRED_NUMERIC_ERROR_MESSAGE)
      .required(REQUIRED_ERROR_MESSAGE),
    [FormName.Analyte]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Operator]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.Location]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
    [FormName.TestKit]: Yup.string()
      .required(REQUIRED_ERROR_MESSAGE)
      .test(
        FormName.TestKit,
        'Not a valid test kit',
        (value: string | undefined) =>
          // not sure why existential operator `value?.toLowerCase()` not working here
          lowerCaseValidTestKits.includes(value ? value.toLowerCase() : '')
      ),
    [FormName.SampleType]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  })

  return (
    <Page
      testId="QuickCheckForm"
      style={{ maxWidth: 1250 }}
      title={intlMessageForId('QuickCheck.Form.Header')}
      headerElement={
        <SecondaryButton to={Path.Reports_Quickcheck} as={Link}>
          Report Builder
        </SecondaryButton>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values: FormValues, { resetForm: reset }) => {
          setResetForm(reset)
          createManualQuickcheck({
            variables: {
              analyte: values[FormName.Analyte],
              location: values[FormName.Location],
              numericResult: values[FormName.ResultNumeric],
              operator: values[FormName.Operator],
              sampleId: values[FormName.SampleId].toUpperCase(),
              sampleType: values[FormName.SampleType],
              testKit: values[FormName.TestKit].toUpperCase(),
            },
          })
        }}
      >
        {() => (
          <>
            <div>
              Submit QuickCheck sample results manually if your QuickScan is not
              connected to TotalHub.
            </div>
            <div style={{ paddingTop: 48 }} />
            <Form style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  columnGap: 24,
                }}
              >
                <SocialDistancing
                  spacing="24px"
                  style={{
                    width: '50%',
                    minWidth: 400,
                  }}
                >
                  <HelpConatiner>
                    <FormInput name={FormName.SampleId} label="SAMPLE ID" />
                    <ExternalLink
                      href={Path.Quality_QuickCheck_Help_SampleId}
                      style={{
                        fontSize: 14,
                        color: 'rgba(31, 148, 191, 0.9)',
                      }}
                    >
                      Need help finding your sample ID?
                    </ExternalLink>
                  </HelpConatiner>
                  <FormSelect
                    name={FormName.SampleType}
                    label="SAMPLE TYPE / MATRIX"
                    options={buildOptions(['Corn', 'Wheat'])}
                  />
                  <FormInput name={FormName.Operator} label="OPERATOR" />
                  <FormInput name={FormName.Location} label="LOCATION" />
                  <FormInput
                    name={FormName.ResultNumeric}
                    label="NUMERIC RESULT"
                  />
                  <FormSelect
                    name={FormName.Analyte}
                    label="ANALYTE"
                    options={buildOptions(['Aflatoxin', 'DON'])}
                  />
                  <HelpConatiner>
                    <FormInput name={FormName.TestKit} label="TEST KIT" />
                    <ExternalLink
                      href={Path.Quality_QuickCheck_Help_TestKitId}
                      style={{ fontSize: 14, color: 'rgba(31, 148, 191, 0.9)' }}
                    >
                      Need help finding your test kit ID?
                    </ExternalLink>
                  </HelpConatiner>
                  <PrimaryButton type="submit">
                    {intlMessageForId('Buttons.Submit')}
                  </PrimaryButton>
                </SocialDistancing>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Page>
  )
}

export default QuickCheckForm

import React, { FC } from 'react'
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import { Formik, Form } from 'formik'
import { get, includes, property } from 'lodash'
import { filter, flow, groupBy, map } from 'lodash/fp'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { LinkButton, PrimaryButton, SecondaryButton } from 'components/Button'
import { SectionDivider } from 'components/Divider'
import {
  ErrorMessage,
  FormCheckbox,
  FormSelect,
  REQUIRED_ERROR_MESSAGE,
  VALID_EMAIL_ERROR_MESSAGE,
} from 'components/FormElements'
import {
  ContentHeader,
  EmailInput,
  FirstNameInput,
  handleCompleted,
  handleError,
  LastNameInput,
  Subheader,
} from 'components/FormElements/common'
import Group from 'components/FormElements/Group'
import Page from 'components/Page'
import { Loading, Error } from 'components/Placeholders'
import SocialDistancing from 'components/SocialDistancing'
import { intlMessageForId } from 'localization'
import Color from 'types/color'
import { FormName } from 'types/forms'
import { LayoutDirection, Path } from 'types/enums'
import { logError, mapObject } from 'utils'
import { OperatorCourse, OperatorTrainingFrequency } from './types'

// TODO: add form submit test coverage

interface FormValues {
  [FormName.Email]: string
  [FormName.FirstName]: string
  [FormName.LastName]: string
  [FormName.Frequency]: '' | OperatorTrainingFrequency
  [FormName.Courses]: Array<string>
}

const initialValues: FormValues = {
  [FormName.Email]: '',
  [FormName.FirstName]: '',
  [FormName.LastName]: '',
  [FormName.Frequency]: '',
  [FormName.Courses]: [],
}

export const validationSchema = Yup.object().shape({
  [FormName.Email]: Yup.string()
    .email(VALID_EMAIL_ERROR_MESSAGE)
    .required(REQUIRED_ERROR_MESSAGE),
  [FormName.FirstName]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.LastName]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.Frequency]: Yup.string().required(REQUIRED_ERROR_MESSAGE),
  [FormName.Courses]: Yup.array().min(1, REQUIRED_ERROR_MESSAGE),
})

export const OPERATOR_COURSES_QUERY = gql`
  query {
    operatorCourses {
      id
      metadata {
        checked
        groupName
        index
      }
      name
    }
  }
`

export const SUBMIT_OPERATOR_TRAINING_MUTATION = gql`
  mutation(
    $courseIds: [Int]
    $email: String!
    $firstName: String!
    $frequency: OperatorTrainingFrequency
    $lastName: String!
  ) {
    submitOperatorTraining(
      input: {
        courseIds: $courseIds
        email: $email
        firstName: $firstName
        frequency: $frequency
        lastName: $lastName
      }
    ) {
      id
    }
  }
`

const AssignTrainingForm: FC = () => {
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(OPERATOR_COURSES_QUERY)

  const [submitOperatorTraining] = useMutation(
    SUBMIT_OPERATOR_TRAINING_MUTATION,
    {
      onCompleted: ({ submitOperatorTraining: { id } }) => {
        if (id) {
          handleCompleted(
            navigate,
            Path.Quality_AssignedTrainings,
            intlMessageForId('Training.Form.Confirmations.Added')
          )
        }
      },
      onError: (e: ApolloError) => {
        handleError(navigate, Path.Quality_AssignTrainingForm)
        logError(e.message, 'not-production')
      },
    }
  )

  if (loading) return <Loading />
  if (error) return <Error />

  const operatorCourses = data?.operatorCourses
  const groupedCourses = groupBy('metadata.groupName')(operatorCourses)

  const defaultChecked = flow(
    filter(property('metadata.checked')),
    map((course: OperatorCourse) => {
      return course.id.toString()
    })
  )(operatorCourses)

  return (
    <Page
      testId="AssignTrainingForm"
      title={intlMessageForId('Training.AssignTrainingForm.Header')}
    >
      <Formik
        initialValues={{
          ...initialValues,
          ...{ [FormName.Courses]: defaultChecked },
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values: FormValues) => {
          submitOperatorTraining({
            variables: {
              courseIds: values[FormName.Courses].map((id) => Number(id)),
              email: values[FormName.Email],
              firstName: values[FormName.FirstName],
              frequency: values[FormName.Frequency],
              lastName: values[FormName.LastName],
            },
          })
        }}
      >
        {({ values: formValues }) => (
          <>
            <SectionDivider style={{ marginBottom: 42 }} />
            <Form style={{ display: 'flex' }}>
              <SocialDistancing
                spacing="24px"
                style={{
                  width: '50%',
                }}
              >
                <EmailInput />
                <FirstNameInput />
                <LastNameInput />
                <FormSelect
                  name={FormName.Frequency}
                  label={intlMessageForId('Training.FieldLabels.FREQUENCY')}
                  emptyState={intlMessageForId(
                    'Training.Form.FieldPlaceholders.FREQUENCY'
                  )}
                  details={intlMessageForId(
                    'Training.Form.FrequencySelect.Details'
                  )}
                  options={[
                    {
                      label: intlMessageForId(
                        'Training.Form.FrequencySelect.Options.NONE'
                      ),
                      value: 'NONE',
                    },
                    {
                      label: intlMessageForId(
                        'Training.Form.FrequencySelect.Options.EVERY_6_MONTHS'
                      ),
                      value: 'EVERY_6_MONTHS',
                    },
                    {
                      label: intlMessageForId(
                        'Training.Form.FrequencySelect.Options.EVERY_12_MONTHS'
                      ),
                      value: 'EVERY_12_MONTHS',
                    },
                  ]}
                />
                <SocialDistancing
                  spacing="16px"
                  direction={LayoutDirection.Horizontal}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <LinkButton
                    to={Path.Quality_AssignedTrainings}
                    Component={SecondaryButton}
                  >
                    {intlMessageForId('Buttons.Cancel')}
                  </LinkButton>
                  <PrimaryButton type="submit">
                    {intlMessageForId('Buttons.Submit')}
                  </PrimaryButton>
                </SocialDistancing>
              </SocialDistancing>
              <SocialDistancing
                spacing="24px"
                style={{
                  marginLeft: 48,
                  width: '50%',
                }}
              >
                <div>
                  <ContentHeader>
                    {intlMessageForId('Training.Form.Sections.Courses.Header')}
                  </ContentHeader>
                  <Subheader>
                    {intlMessageForId(
                      'Training.Form.Sections.Courses.Subheader'
                    )}
                  </Subheader>
                  <ErrorMessage forName={FormName.Courses} />
                </div>
                {mapObject(groupedCourses, (key, value) => (
                  <Group title={key} key={key}>
                    <SocialDistancing spacing="12px">
                      {map((course: OperatorCourse) => {
                        const { name, id } = course

                        const checked = includes(
                          get(formValues, FormName.Courses, []),
                          id.toString()
                        )

                        return (
                          <div
                            style={{
                              width: '100%',
                              padding: '24px 16px',
                              backgroundColor: checked
                                ? Color.SelectedBackground
                                : Color.UnselectedBackground,
                              borderRadius: 8,
                            }}
                            key={id}
                          >
                            <FormCheckbox
                              id={id.toString()}
                              checked={checked}
                              name={FormName.Courses}
                              value={id}
                              labelStyle={{ alignItems: 'center' }}
                              displayError={false}
                            >
                              <div>{name}</div>
                            </FormCheckbox>
                          </div>
                        )
                      })(value)}
                    </SocialDistancing>
                  </Group>
                ))}
              </SocialDistancing>
            </Form>
          </>
        )}
      </Formik>
    </Page>
  )
}

export default AssignTrainingForm

import { ID } from 'types'

export enum Path {
  Home = '/',
  Menus_QualityAndTraining = '/menus/quality-and-training',
  Menus_Reports = '/menus/reports',
  QuickScan = '/quick-scan',
  QuickScan_LoadAcceptance = '/quick-scan/load-acceptance',
  QuickScan_Gmo = '/quick-scan/gmo',
  QuickScan_Mycotoxin = '/quick-scan/mycotoxin',
  QuickScan_Allergen = '/quick-scan/allergen',
  Labs = '/labs',
  Labs_Gmo = '/labs/gmo',
  Labs_Mycotoxin = '/labs/mycotoxin',
  Labs_Results = '/labs/results',
  Labs_Orders = '/labs/orders',
  Reports = '/reports',
  Reports_TestResults = '/reports/test-results',
  Reports_Quickcheck = '/reports/quick-check',
  Reports_Labs = '/reports/labs',
  Reports_Alerts = '/reports/alerts',
  AdminSettings = '/admin-settings',
  AdminSettings_Users = '/admin-settings/users',
  AdminSettings_Lab = '/admin-settings/lab',
  Account = '/account',
  HelpCenter = '/helpcenter',
  HelpCenter_Guides = '/helpcenter/guides',
  HelpCenter_QuickScan = '/helpcenter/quick-scan',
  Quality = '/quality',
  Quality_QuickScanCalibration = '/quality/quickscan-calibration',
  Quality_AssignedTrainings = '/quality/assigned-trainings',
  Quality_AssignTrainingForm = '/quality/assigned-trainings/new',
  Quality_QuickCheckForm = '/quality/quickcheck/new',
  Quality_QuickCheck_Help_SampleId = '/quality/quickcheck/help/sample-id',
  Quality_QuickCheck_Help_TestKitId = '/quality/quickcheck/help/test-kit-id',
  Analytics = '/analytics',
  Analytics_QuickScan = '/analytics/quickscan',
  Analytics_QuickScanForm = '/analytics/quickscan/new',
}

export const getDetailsPath: (root: Path, id: ID) => string = (root, id) =>
  `${root}/${id}`

export const getNewPath: (root: Path) => string = (root) => `${root}/new`

export enum DateRange {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
  Custom = 'CUSTOM',
}

export enum LayoutDirection {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

export enum LoadOutcome {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum Concern {
  Mycotoxin = 'Mycotoxin',
  GMO = 'GMO',
}
